import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag-next"

createApp(App)
  .use(router)
  .use(VueGtag, {
    property: {
      id: "G-H4BH7TX5Y6"
    }
  })
  .mount('#app')
